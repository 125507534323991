import React, { useEffect, useState } from "react";
import Support from "../components/Support";
import MyCourses from "../components/MyCourses";
import { useNavigate } from "react-router-dom";
import { userAuthenticated } from "../utils/util";


const MyLearning = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const validUser = userAuthenticated(document.cookie);
    !validUser && navigate("/login");
    // eslint-disable-next-line
  }, []);

  const [activeTab, setActiveTab] = useState("My Courses");
  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="mainContainer">
        {/* heading and links container  */}
        <div className="flex flex-col bg-[--blue-bg-color] text-white px-8 md:px-24 mt-12 py-2">
          <h1 className="text-4xl font-bold mb-10 pt-8">My Learnings</h1>
          <ul className="flex gap-8">
            <li
              className={`text-lg font-semibold cursor-pointer underline-offset-4 ${
                activeTab === "My Courses"
                  ? "underline text-white"
                  : "text-gray-300"
              } `}
              onClick={() => handleClick("My Courses")}
            >
              My Courses
            </li>
            <li
              className={`text-lg font-semibold cursor-pointer underline-offset-4 ${
                activeTab === "Support"
                  ? "underline text-white"
                  : "text-gray-300"
              } `}
              onClick={() => handleClick("Support")}
            >
              Support
            </li>
          </ul>
        </div>
        {activeTab === "My Courses" ? <MyCourses /> : <Support />}
      </div>
    </>
  );
};

export default MyLearning;
