import React from "react";
import dataIcon from "../icons/icons8-algorithm-48.png";
import leftBannerImg from "../assets/clip-reading-of-books-1.png";
import questionsIcon from "../icons/icons8-questions-100.png";
import interviewIcon from "../icons/icons8-interview-64.png";
import placementIcon from "../icons/icons8-career-48.png";

function Feature() {
  return (
    <section className="bg-[--feature-section-bg-color] dark:bg-black">

      {/* container div  */}
      <div className="contianer max-w-xl p-6 py-12 mx-auto spcae-y-24 lg:px-8 lg:max-w-7xl">

        {/* headings div  */}
        <div className="text-center">
          <h1 className="text-2xl text-[--h1-color] dark:text-gray-300 font-bold">
            WHY CODEMAP?
          </h1>
          <h2 className="text-2xl md:text-4xl text-[--h2-color] dark:text-[--dark-h2-color] font-bold">
            Making learning easier and
          </h2>
          <h2 className="text-2xl md:text-4xl text-[--h2-color] dark:text-[--dark-h2-color] font-bold">
            more convenient for you.
          </h2>
        </div>

        <div className="container grid md:grid-cols-1 lg:grid-cols-2 p-2 gap-8 lg:gap-0 lg:px-16 lg:py-8 items-center">
          {/* image containing left div  */}
          <div className="">
            <img src={leftBannerImg} alt="" />
          </div>

          {/* feature points container  */}
          <div className="">
            {/* feature 1 box  -- data structure */}
            <div className="flex py-2 lg:py-6 lg:pr-8 gap-2">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 rounded-md dark:[--dark-bg-color] dark:[--dark-font-color]  shadow-xl">
                  <img className="" src={dataIcon} alt="" />
                </div>
              </div>
              <div className="">
                <h4 className="text-lg font-bold dark:text-[--dark-h4-color]">
                  Data Structure
                </h4>
                <p className="mt-2 font-semibold dark:text-[--dark-p-color]">
                  Data structures are the problem-solving pillars of coding.
                  Learn all the foundational knowledge about all of the popular
                  data structure that you need, to be well-versed in interviews.
                </p>
              </div>
            </div>

            {/* feature 2 box  -- hand picked questions */}
            <div className="flex py-2 lg:py-6 lg:pr-8 gap-2">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 rounded-md dark:[--dark-bg-color] dark:[--dark-font-color] shadow-xl">
                  <img className="" src={questionsIcon} alt="" />
                </div>
              </div>
              <div className="">
                <h4 className="text-lg font-bold dark:text-[--dark-font-color]">
                  Hand Picked Question
                </h4>
                <p className="mt-2 font-semibold dark:text-[--dark-font-color]">
                  Get access to hand-picked coding interview questions across
                  categories & difficulty levels that will prepare you for every
                  interview you would encounter.
                </p>
              </div>
            </div>

            {/* feature 3 box  -- Mock Interviews  */}
            <div className="flex py-2 lg:py-6 lg:pr-8 gap-2">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 rounded-md dark:[--dark-bg-color] dark:[--dark-font-color] shadow-xl">
                  <img className="" src={interviewIcon} alt="" />
                </div>
              </div>
              <div className="">
                <h4 className="text-lg font-bold dark:text-[--dark-font-color]">
                  Mock Interviews
                </h4>
                <p className="mt-2 font-semibold dark:text-[--dark-font-color]">
                  Prepare yourself with the mock intervies provided by codemap.
                  Boost your confidense and Be Ready.
                </p>
              </div>
            </div>

            {/* feature box 4  -- placement preparation */}
            <div className="flex py-2 lg:py-6 lg:pr-8 gap-2">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center w-12 h-12 rounded-md dark:[--dark-bg-color] dark:[--dark-font-color] shadow-xl">
                  <img className="" src={placementIcon} alt="" />
                </div>
              </div>
              <div className="">
                <h4 className="text-lg font-bold dark:text-[--dark-font-color]">
                  Placement Preparation
                </h4>
                <p className="mt-2 font-semibold dark:text-[--dark-font-color]">
                  Ace the placement interviews by being knowledgeable in all
                  well-known data structures, popular problem-solving methods,
                  and core computer sciences.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Feature;
