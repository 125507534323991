import React from "react";
import CourseBox from "./CourseBox";

const Courses = ({ course }) => {
  return (
    <section className="px-8 py-12 bg-[--light-bg-color] from-gray-300 to-gray-50">
      <div className="container mx-auto">
        {/* courses heading  */}
        <h1 className="text-center text-3xl text-[--h1-color] dark:text-[--tagline-color] font-semibold">
          Available Courses
        </h1>

        {/* courses container  */}
        <div className="flex items-center justify-center gap-6 flex-wrap py-8">
          {/* course box  */}

          {course.map((m, i) => (
            <>
              <CourseBox key={i + "coursebox"} type="free" course={m} />
            </>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Courses;
