// import React from "react";
import starIcon from "../icons/icons8-star-30.png";
import lineBreaker from "../assets/line breaker pattern.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { review } from "../data/review";

const Testimoni = (props) => {
  const item = props.item;
  const renderStars = (starCount) => {
    let stars = [];
    for (let i = 0; i < starCount; i++) {
      stars.push(<img className="h-6 w-6" src={starIcon} alt="" key={i} />);
    }
    return stars;
  };

  return (
    <div className="grid p-6 h-fit py-12">
      <div className="place-self-center flex flex-col gap-2 items-center justify-center text-center max-w-[700px]">
        <img className="h-36 w-36 rounded-full" src={item.image} alt="" />
        <h2 className="text-[--h2-color] dark:text-white font-bold text-xl">
          {item.name}
        </h2>
        <div className="flex gap-1">{renderStars(item.star)}</div>
        <p className="text-[--p-color] dark:text-white font-semibold italic">
          {item.review}
        </p>
      </div>
    </div>
  );
};

const Testimonials = () => {
  return (
    <section className="py-12 from-gray-300 to-gray-50 dark:bg-black h-fit max-h-screen overflow-hidden md:px-8">
      <div className="container mx-auto">
        <h1 className="text-center text-4xl font-semibold text-[--h1-color] dark:text-[--tagline-color]">
          TESTIMONIALS
        </h1>
        <div className="flex justify-center mb-8 dark:invert">
          <img className="h-8" src={lineBreaker} alt="" />
        </div>
        {/* <!-- Slider main container --> */}
        <Swiper
          spaceBetween={100}
          slidesPerView={1}
          navigation={true}
          modules={[Navigation, Pagination]}
          pagination={true}
        >
          {/* <SwiperSlide><Testimoni /></SwiperSlide>
        <SwiperSlide><Testimoni /></SwiperSlide>
        <SwiperSlide><Testimoni /></SwiperSlide>
        <SwiperSlide><Testimoni /></SwiperSlide> */}
          {review.map((item) => {
            return (
              <SwiperSlide>
                <Testimoni item={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
