import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function Checkout(userInfo) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponErr, setCouponErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const totalAmount = () => {
    let balance = 0;
    cartItems.map((m) => (balance += parseInt(m.price)));
    return balance;
  };

  const totalNoDiscountAmount = () => {
    let balance = 0;
    cartItems.map((m) => (balance += parseInt(m.previousPrice)));
    return balance;
  };

  const getCoupon = () => {
    const couponCode = document.getElementById("couponcode").value;
    if (couponCode === "WELCOME100") {
      setCouponDiscount(totalAmount() - 5);
      setCouponErr(false);
    } else if (couponCode === "ONE") {
      setCouponDiscount(totalAmount() - 1);
      setCouponErr(false);
    } else {
      setCouponDiscount(0);
      setCouponErr(true);
    }
  };
  const handlePayment = async () => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    const amount = (totalAmount()- couponDiscount) * 100;
    const orderPayload = {
      amount,
      currency: "INR",
      receipt: "receipt#1",
      notes: {},
    };
    // const razorpay = new Razorpay({
    //   key_id: process.env.REACT_APP_KEY_ID, // Replace with your Razorpay Key ID
    //   key_secret: process.env.REACT_APP_SECRET, // Replace with your Razorpay Key Secret
    // });
    // Create order by calling the server endpoint
    const response = await axios.post(
      `${process.env.REACT_APP_BE_HOST}/api/order/checkout`,
      orderPayload
    );
    const order = response.data.appData;
    // Open Razorpay Checkout
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Replace with your Razorpay key_id
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "CODEMAP",
      description: "Test Transaction",
      order_id: order.id, // This is the order_id created in the backend
      callbackurl:"https://www.thecodemap.in/sucess",
      prefill: {
        name: userInfo.name,
        email: userInfo.email,
        contact: userInfo.contact,
      },
      theme: {
        color: "#F37254",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
    setIsButtonDisabled(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setCouponDiscount(0);
  }, [cartItems]);
  return (
    <div className="checkoutBox w-[90%] md:w-[70%] lg:w-[25%] lg:max-w-[27%] h-fit min-h-[30vh] lg:border-l border-gray-400 p-4 mb-20 lg:mb-0">
      {/* total amount  */}
      <h1 className="text-3xl mb-4 font-semibold">Summary</h1>
      <h1 className="text-lg flex items-center justify-between font-semibold my-3 dark:text-[--tagline-color]">
        <span>Subtotal</span> <span>&#8377; {totalNoDiscountAmount()}</span>
      </h1>
      <h1 className="text-green-600 flex items-center justify-between font-semibold my-3">
        <span>Discount</span>{" "}
        <span>&#8377; {totalNoDiscountAmount() - totalAmount()}</span>
      </h1>
      <h1 className="text-green-600 flex items-center justify-between font-semibold my-3">
        <span>Coupon Discount</span> <span>&#8377; {couponDiscount}</span>
      </h1>
      <h1 className="text-xl flex items-center justify-between font-semibold my-3 dark:text-[--tagline-color]">
        <span>Total</span> <span>&#8377; {totalAmount() - couponDiscount}</span>
      </h1>
      <p className="text-right text-sm font-semibold dark:text-[--tagline-color]">Including GST</p>
      <button
        className={`bg-[--button-color] hover:bg-[--button-hover-color] hover:text-white text-white py-3 w-full rounded font-bold transition-all delay-75 ${
          isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handlePayment}
        disabled={isButtonDisabled}
      >
        {isLoading ? (
          <svg
            className="w-5 h-5 animate-spin mx-auto"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              strokeWidth="4"
              className="stroke-white"
            />
            <path
              d="M4 12a8 8 0 1 0 16 0 8 8 0 1 0-16 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          "Checkout"
        )}
      </button>
      <div className="border-t-2 border-gray-500 my-4"></div>
      <h1 className="text-xl flex items-center justify-between font-semibold my-3">
        Apply Coupon
      </h1>
      <div className="flex text-xl">
        <input
          className="w-[70%] p-2 border border-gray-300 rounded-l-md"
          placeholder="Coupon Code"
          id="couponcode"
        ></input>
        <button
          className="w-[30%] p-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600"
          onClick={getCoupon}
        >
          Apply
        </button>
      </div>
      {couponErr && <p className="text-gray-600 dark:text-[--tagline-color]">Invalid Coupon</p>}
      {couponDiscount > 0 && <p className="text-gray-600 dark:text-[--tagline-color]">Coupon Applied</p>}
    </div>
  );
}

export default Checkout;
