import React, { useEffect, useState } from "react";
import { MdOutlineDeleteForever } from "react-icons/md"; // delete icon
import { HiOutlineStar } from "react-icons/hi2"; // blank star icon
import { HiStar } from "react-icons/hi2"; // filled star icon
import { FaTag } from "react-icons/fa";
import emptyCartImage from "../assets/bloom-a-man-looks-at-a-blank-sheet-of-paper-in-puzzlement.png";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../redux/cart/cartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { MdScreenSearchDesktop } from "react-icons/md";
import axios from "axios";
import { useRazorpay } from "react-razorpay";
import Checkout from "../components/Checkout";
import CheckoutForm from "../components/CheckoutForm";

const Cart = () => {
  const { Razorpay } = useRazorpay();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);
  const [userInfo, setUserInfo] = useState(null);

  const removeCartItem = (cid) => {
    dispatch(removeFromCart({ id: cid }));
    toast.warn("Removed from cart", {
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  //   ratings star return function
  const starCountReturn = (curren_ratings) => {
    const fullStar = Math.floor(curren_ratings);
    const halfStar = curren_ratings % 1 >= 0.5;
    const emptyStars = 5 - Math.ceil(curren_ratings);
    let ratings = [];
    for (let i = 0; i < fullStar; i++) {
      ratings = [<HiStar key={i + "filledStar"} />, ...ratings]; // Full star symbol
    }
    if (halfStar) {
      ratings = [
        ...ratings,
        <HiStar key={"halfStar"} className="text-yellow-500 opacity-50" />,
      ]; // Half star symbol
    }
    for (let j = 0; j < emptyStars; j++) {
      ratings = [...ratings, <HiOutlineStar key={j + "blankStar"} />]; // Empty star symbol
    }
    return ratings;
  };

  return (
    <>
      <div className="mt-20 pt-6">
        {/* toast container  */}
        <ToastContainer />

        {/* all cart items heading  */}
        <h1 className="text-3xl mx-28 font-semibold text-[--h1-color] dark:text-[--tagline-color]">
          Your Cart
        </h1>

        {cartItems.length > 0 ? (
          <>
            {/* main conatiner  */}
            <div className="container_cart pt-4 min-h-screen flex flex-col items-center lg:items-start lg:justify-evenly lg:flex-row justify-start gap-12 lg:gap-0 lg:px-10">
              {/* second container  */}
              <div className="container2_cart w-[90%] lg:w-[65%] lg:max-w-[65%]">
                {/* total items count heading  */}
                <h3 className="text-lg font-semibold text-[--h3-color] my-2 dark:text-[--tagline-color]">
                  {cartItems.length}{" "}
                  {cartItems.length <= 1 ? "course" : "courses"} in cart
                </h3>

                {/* all course present in cart container  */}
                <div className="allCoursesContainer_cart">
                  {cartItems.map((m, i) => (
                    <>
                      {/* each course box  */}
                      <div
                        key={m}
                        id={m.id}
                        className="py-4 border-t border-gray-400"
                      >
                        <div className="eachCourseBox justify-between flex flex-wrap h-fit p-2 items-center">
                          {/* course thumbnail and details container  */}
                          <div className="flex gap-4">
                            {/* course thumbnail  */}
                            <div className="courseThumbnail object-cover w-32">
                              <img src={m.thumbnail} alt="course thumbnail" />
                            </div>

                            {/* course title author ratings div  */}
                            <div className="courseDetials h-[90%]">
                              {/* course title  */}
                              <p className="text-lg font-medium my-1 dark:text-[--tagline-color]">
                                {m.title}
                              </p>

                              {/* course author  */}
                              <p className="text-sm text-gray-500  my-1 dark:text-[--tagline-color]">
                                By {m.courseCreator}
                              </p>

                              {/* course ratings  */}
                              <p className="flex items-center text-sm my-1">
                                <span className="text-yellow-500 flex">
                                  <span className="text-black mx-1 dark:text-[--tagline-color]">
                                    {m.ratings}
                                  </span>
                                  {starCountReturn(m.ratings)}{" "}
                                  <span className=" text-gray-600 dark:text-[--tagline-color]">
                                    ({m.reviewCount} ratings)
                                  </span>
                                </span>
                              </p>

                              {/* course duration / lecture count / courselevel para  */}
                              <p className="text-sm text-gray-500 my-1">
                                <span>{m.duration} total hours </span> &bull;
                                <span> {m.lectureCount} lectures </span> &bull;
                                <span> {m.level} Level</span>
                              </p>
                            </div>
                          </div>

                          {/* remove course btn and price  */}
                          <div className="course_price_btns flex gap-4">
                            {/* remove item btn with icon  */}
                            <button
                              onClick={() => removeCartItem(m.id)}
                              className="flex items-center gap-1 font-semibold text-blue-500 mx-4 hover:text-blue-700"
                            >
                              <MdOutlineDeleteForever size={20} /> Remove
                            </button>

                            {/* course prev price and current price  */}
                            <div className="p-4">
                              <p className="flex gap-1 items-center text-[--price-color] font-bold text-lg">
                                <span>&#8377;{m.price}</span>
                                <FaTag size={15} />
                              </p>
                              <p className="line-through text-sm font-semibold dark:text-[--tagline-color]">
                                &#8377;{m.previousPrice}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>

              {/* checkoutBox */}
              {userInfo === null ? (<CheckoutForm setUserInfo={setUserInfo}/>)
              : (<Checkout userInfo={userInfo}/>)}
            </div>
          </>
        ) : (
          // empty cart view
          <>
            <div className="min-h-screen p-8 flex flex-col items-center justify-start">
              <Link
                className="text-xl font-medium my-4 flex items-center gap-2 bg-blue-500 rounded px-2 py-2 text-white"
                to="/courses"
              >
                {" "}
                <MdScreenSearchDesktop size={25} /> Explore Courses
              </Link>
              <img className="h-[40vh]" src={emptyCartImage} alt="empty" />
              <h1 className="text-2xl font-semibold">Your Cart is Empty</h1>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
