import React, { useEffect, useState } from "react";

const ThemeToggleButton = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  const toggleTheme = () => {
    if(!isDarkMode){
      localStorage.setItem("theme","dark");
      setIsDarkMode(true);
    }else{
      localStorage.setItem("theme","light");
      setIsDarkMode(false);
    }
  };
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
      document.documentElement.style.setProperty('--blue-bg-color','#000');
      document.documentElement.style.setProperty('--light-bg-color','#000');

    } else {
      document.body.classList.remove('dark');
      document.documentElement.style.setProperty('--blue-bg-color','rgb(22, 49, 131)');
      document.documentElement.style.setProperty('--light-bg-color','#eee');
    }
  }, [isDarkMode]);

  return (
    <>
      <button
        class="w-14 h-8 rounded-full bg-white flex items-center transition duration-300 focus:outline-none shadow"
        onClick={toggleTheme}
      >
        <div
          onClick={toggleTheme}
          class={`w-8 h-8 relative rounded-full transition duration-500 transform p-1 text-white ${
            isDarkMode
              ? "bg-gray-700 translate-x-full"
              : "bg-yellow-500 -translate-x-2"
          }`}
        >
          {isDarkMode ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          )}
        </div>
      </button>
    </>
  );
};

export default ThemeToggleButton;
