import React, { useEffect, useState } from "react";
import { FaVideo } from "react-icons/fa6";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { MdOutlineDoubleArrow, MdGroups2 } from "react-icons/md";
import codingImage from "../assets/coding background.jpg";
import googleLogo from "../assets/logos/google-svgrepo-com.svg";
import amazonLogo from "../assets/logos/amazon-2-logo-svgrepo-com.svg";
import walmartLogo from "../assets/logos/walmart-logo-svgrepo-com.svg";
import juspayLogo from "../assets/logos/Juspay.jpg";
import tcsLogo from "../assets/logos/TCS.NS.png";
import hashdinLogo from "../assets/logos/hashedin-logo.png";
import { FaUserGraduate } from "react-icons/fa";
import { BsPersonFillUp } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { mernCourseDetails } from "../data/mernCourseContent";
import { dsaCourseDetails } from "../data/dsaCourseContent";
import { FaTag } from "react-icons/fa";

const HighlightedCourse = () => {
  const location = useLocation();
  const [showSection, setShowSection] = useState({});
  const [courseDetails, setCourseDetails] = useState([]);
  const [courseName, setcourseName] = useState("");

  const toggleSection = (secId) => {
    setShowSection((prevState) => ({
      ...prevState,
      [secId]: !prevState[secId],
    }));
    console.log(showSection);
  };
  useEffect(() => {
    if (location.search !== "") {
      const queryParams = new URLSearchParams(location.search);
      const course = queryParams.get("courseName");
      setcourseName(course);
      if (course === "dsa") {
        setCourseDetails(dsaCourseDetails);
      } else {
        setCourseDetails(mernCourseDetails);
      }
    }
  }, [location]);

  return (
    <div className="bg-[--light-bg-color] dark:text-[--tagline-color]">
      <div className="mt-16 container mx-auto px-4 min-h-[65vh]">
        <div className="flex flex-wrap h-fit p-6 lg:p-0 mb-20 md:mb-40">
          <div className="w-full md:w-1/2 lg:pl-16 pt-8 lg:pt-14">
            <h1 className="text-3xl font-semibold my-8 leading-[3rem]">
              {courseDetails.title1}{" "}
              <span className="text-blue-600 dark:text-[--tagline-color]">{courseDetails.courseName}</span>{" "}
              {courseDetails.title2}
            </h1>
            <p className="flex gap-6 text-lg my-3">
              <FaVideo color="blue" size={25} /> {courseDetails.watchHours}
            </p>
            <p className="flex gap-6 text-lg my-3">
              <MdGroups2 color="blue" size={25} /> {courseDetails.doubtSupport}
            </p>
            <p className="flex gap-6 text-lg my-3">
              <FaChalkboardTeacher color="blue" size={25} />{" "}
              {courseDetails.liveProject}
            </p>
            <div className="p-2">
              <p className="line-through text-lg font-semibold">
                &#8377;{courseDetails.previousPrice}
              </p>
              <p className="flex gap-1 items-center text-[--price-color] font-bold text-lg">
                <span>&#8377;{courseDetails.price}</span>
                <FaTag size={15} />
              </p>
            </div>
            <button className="px-12 py-3 bg-[--button-color] hover:bg-[--button-hover-color] rounded text-lg font-semibold text-white my-8 flex gap-4">
              <span>Enroll Now</span> <MdOutlineDoubleArrow size={25} />
            </button>
          </div>

          <div className="w-full md:w-1/2 p-4 lg:p-0">
            <div className="object-cover w-[18rem] h-[18rem] md:w-[23rem] md:h-[23rem] mx-auto lg:mt-24 lg:ml-16">
              <img src={codingImage} alt="" />
            </div>
          </div>
        </div>
        <div className="my-8 lg:my-0">
          <h2 className="text-center font-medium text-3xl">
            What will you <span className="text-blue-500">learn?</span>
          </h2>

          <div>
            {courseDetails.courseContent?.map((section, index) => (
              <div className="p-4 bg-gray-100 dark:bg-gray-600 rounded shadow-md my-4 w-11/12 md:w-4/5 mx-auto select-none">
                <p
                  onClick={() => toggleSection(index + section.sectionTitle)}
                  className="flex justify-between items-center text-lg font-medium py-2"
                >
                  {section?.sectionTitle}{" "}
                  <IoIosArrowUp
                    className={`cursor-pointer ${
                      !showSection[index + section.sectionTitle] && "rotate-180"
                    } transition-all ease-in-out delay-150`}
                  />
                </p>
                <div
                  key={index + section.sectionTitle}
                  className={`${
                    !showSection[index + section.sectionTitle] ? "hidden" : ""
                  } transition-all ease-in-out delay-200 px-4`}
                >
                  {section.sectionVideos?.map((videos, vidIndex) => (
                    <>
                      <p
                        key={vidIndex + videos.videoTitle}
                        className="font-medium text-gray-700 py-2 dark:text-[--tagline-color]"
                      >
                        {videos.videoTitle}
                      </p>
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="py-8 my-12">
          <h1 className="text-center text-3xl font-medium">
            Land a job in your <span className="text-blue-600">Dream</span>{" "}
            company
          </h1>
          <div className="flex flex-wrap gap-4 items-center justify-evenly p-4 my-6">
            <div className="h-20 w-20 object-contain">
              <img src={tcsLogo} alt="" />
            </div>
            <div className="h-20 w-20 object-contain">
              <img src={googleLogo} alt="" />
            </div>
            <div className="h-20 w-20 object-contain">
              <img src={amazonLogo} alt="" />
            </div>
            <div className="h-20 w-20 object-contain">
              <img src={walmartLogo} alt="" />
            </div>
            <div className="h-20 w-20 object-contain">
              <img src={juspayLogo} alt="" />
            </div>
            <div className="h-20 w-20 object-contain">
              <img src={hashdinLogo} alt="" />
            </div>
          </div>
          <button className="text-lg font-semibold px-6 py-3 bg-[--button-color] hover:bg-[--button-hover-color] rounded shadow mx-auto text-white block">
            Claim your seat 🔥
          </button>
        </div>

        <div className="p-4 my-12">
          <h2 className="text-center font-semibold text-2xl md:text-3xl">
            Who is{" "}
            <span className="text-blue-500">{courseDetails.courseFor}</span> for
            ?
          </h2>

          <div className="flex gap-12 md:gap-0 flex-wrap items-center justify-evenly py-8">
            <div className="dark:bg-gray-800 w-full md:w-[45%] lg:w-2/6 shadow-2xl rounded-md border-b-4 border-b-blue-700 hover:scale-[1.02] py-8 px-6 transition-all ease-in-out delay-100">
              <FaUserGraduate className="text-blue-600" size={30} />
              <h2 className="my-6 text-xl font-semibold">STUDENTS</h2>
              <p className="text-gray-700 font-medium dark:text-[--tagline-color]">
                {courseDetails.students}
              </p>
            </div>

            <div className="dark:bg-gray-800 w-full md:w-[45%] lg:w-2/6 shadow-2xl rounded-md border-b-4 border-b-blue-700 hover:scale-[1.02] py-8 px-6 transition-all ease-in-out delay-100">
              <BsPersonFillUp className="text-blue-600" size={35} />
              <h2 className="my-6 text-xl font-semibold">
                WORKING PROFESSIONALS
              </h2>
              <p className="text-gray-700 font-medium dark:text-[--tagline-color]">
                {courseDetails.professionals}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighlightedCourse;
