import React from "react";
import demoCertificate from "../assets/demo ceritificate.jpg";

const Certificate = () => {
  return (
    <div className="bg-white dark:bg-black">
      <div className="container mx-auto  py-12">
        <div className="flex flex-col items-center justify-evenly lg:flex-row gap-4 p-6 h-fit place-items-center">
          {/* text box  */}
          <div className="flex justify-center flex-col pl-6 p-4 md:w-[80%]  lg:max-w-[50%]">
            <h4 className="text-yellow-500 font-semibold text-5xl my-1">
              Course certificate
            </h4>
            <p className="dark:text-white font-bold text-2xl my-3">
              Enhance your resume with professional certificate
            </p>
            <p className="text-gray-500 dark:text-gray-300 font-medium">
              You will recieve your certificate upon completion of the course.
            </p>
            <p className="text-gray-500 dark:text-gray-300 font-medium">
              Add your certificate to your CV or your Linkedin profile.
            </p>
          </div>

          {/* image box  */}
          <div className="h-60 md:h-80 w-fit shadow-lg">
            <img
              className="h-full w-full bg-cover"
              src={demoCertificate}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
