import React from "react";
import ill1 from "../assets/illustration 1.jpeg";
import { Link } from "react-router-dom";

function Hero() {
  return (
    // main div
    <div className="pt-12 md:pt-0 max-w-screen px-8 xl:px-12 mx-auto dark:bg-black">
      {/* wrapper div  */}
      <div className="grid grid-flow-row pt-16 pb-4 md:grid-flow-col md:grid-cols-2 container mx-auto">
        {/* left div containg h1 p & button  */}
        <div className="flex flex-col items-start md:mt-12 lg:mt-32 2xl:mt-40 mx:ml-28 lg:ml-20 row-start-2 sm:row-start-1 gap-0 md:gap-2 h-fit w-fit">
          {/* heading  */}
          <h1 className="text-2xl lg:text-4xl 2xl:text-6xl font-medium dark:text-white text-[--h1-color] leading-normal">
            Learn & Become The
          </h1>
          <h1 className="text-2xl lg:text-4xl 2xl:text-6xl font-medium dark:text-white text-[--h1-color] leading-normal">
            <strong>Top 1% Developers</strong>
          </h1>

          {/* para tag  */}
          <p className="text-black dark:text-white mt-4 mb-6 text-xl 2xl:text-2xl font-semibold">
            The Ultimate Guide To Ace SDE Interviews
          </p>

          {/* explore button  */}
          <Link
            to="/courses"
            className="px-12 py-4 text-xl 2xl:text-2xl lg:px-16 xl:px-20 2xl:py-6 font-semibold text-white cursor-pointer hover:bg-blue-600 rounded-lg bg-blue-500 "
          >
            Explore Courses
          </Link>
        </div>

        {/* right div with image  */}
        <div className="flex w-full md:mt-8 lg:mt-0">
          <div className="h-full w-full">
            {/* image  */}
            <img src={ill1} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
