import React from "react";
import ill3 from "../assets/placement illustration.png";
import amazonLogo from "../assets/logos/pngimg.com - amazon_PNG27.png";
import googleLogo from "../assets/logos/pngimg.com - google_PNG19635.png";
import juspayLogo from "../assets/logos/Juspay.jpg";
import tcsLogo from "../assets/logos/TCS.NS.png";
import wiproLogo from "../assets/logos/WIT_BIG.png";
import cognizantLogo from "../assets/logos/Cognizant-Logo.png";
import hashedinLogo from "../assets/logos/hashedin-logo.png";
import walmartLogo from "../assets/logos/walmart-logo-svgrepo-com.svg";

const Placement = () => {
  return (
    <section className="bg-[--light-bg-color] from-gray-300 to-gray-50 py-20 h-fit">
      {/* container div  */}
      <div className="placement-container grid grid-cols-1 gap-8 md:gap-0 md:grid-cols-2 items-center container mx-auto">
        {/* left div with heading and para  */}

        <div className="place-self-center w-80 h-80 md:w-[450px] md:h-96 grid items-center relative .bg-green-300">
          {/* heading div  */}
          <div className="place-self-center">
            <h3 className="text-4xl text-center md:text-left font-bold text-[--font-color] dark:text-[--tagline-color] mb-3">
              PLACEMENTS
            </h3>
            <p className="text-2xl text-center md:text-left font-semibold text-[--p-color] dark:text-[--tagline-color]">
              Get Offers From
            </p>
            <p className="text-2xl text-center md:text-left font-semibold text-[--p-color] dark:text-[--tagline-color]">
              Your Dream Company
            </p>
          </div>

          {/* amazon logo */}
          <img
            className="absolute top-0 left-0 md:left-12 lg:left-0 opacity-60 dark:opacity-100 h-16"
            src={amazonLogo}
            alt=""
          />

          {/* google logo */}
          <img
            className="absolute top-0 right-0 opacity-60 dark:opacity-100 h-16"
            src={googleLogo}
            alt=""
          />

          {/* juspay logo */}
          <img
            className="absolute bottom-0 left-0 md:left-8 lg:left-0 opacity-60 dark:opacity-100 h-16 rounded-full"
            src={juspayLogo}
            alt=""
          />

          {/* tcs logo */}
          <img
            className="absolute top-1/2 -translate-y-1/2 right-0 md:right-8 lg:right-0 opacity-60 dark:opacity-100 h-8"
            src={tcsLogo}
            alt=""
          />

          {/* wipro logo */}
          <img
            className="absolute top-0 left-1/2 -translate-x-1/2 opacity-60 dark:opacity-100 h-10"
            src={wiproLogo}
            alt=""
          />

          {/*  hashedinLogo logo */} 
          <img
            className="absolute bottom-0 left-1/2 -translate-x-1/2 opacity-60 dark:opacity-100 h-16"
            src={hashedinLogo}
            alt=""
          />
          
          {/* cognizantLogo logo */}
          <img
            className="left-4 absolute top-1/2 -translate-y-1/2 md:left-16 lg:left-0 -translate-x-1/2 opacity-60 dark:opacity-100 h-16"
            src={cognizantLogo}
            alt=""
          />

          {/* walmartLogo logo  */}
          <img
            className="absolute bottom-0 -right-2 md:right-0 opacity-60 dark:opacity-100 h-16"
            src={walmartLogo}
            alt=""
          />

        </div>

        {/* right div with image / art  */}
        <div className="flex justify-center relative">
          <img src={ill3} alt="" />
        </div>
        
      </div>
    </section>
  );
};

export default Placement;
