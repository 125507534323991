import React from "react";
import instaIcon from "../icons/icons8-instagram-48.png";
import youtubeIcon from "../icons/icons8-youtube-48.png";
import linkedinIcon from "../icons/icons8-linkedin-48.png";

function Counter() {
  return (
    // stats section
    <section className="p-6 bg-[--blue-bg-color] text-gray-100">
      <div className="container mx-auto grid justify-center grid-cols-1 md:grid-cols-2 text-center lg:grid-cols-3">
        {/* youtuber stats div  */}
        <div className="flex flex-col justify-start m-2 lg:m-6">
          <p className="text-4xl font-bold leadi lg:text-6xl">20K+</p>
          <p className="text-sm sm:text-base flex items-center justify-center gap-2">
            <img className="h-[25px] w-[25px]" src={youtubeIcon} alt="" /> {/*youtube icon*/}
            Subscribers
          </p>
          <a
            target="_blank"
            className="hover:underline"
            href="https://www.youtube.com/@CodeMap"
            rel="noreferrer"
          >
            on Youtube
          </a>
        </div>

        {/* twitter stats div  */}
        <div className="flex flex-col justify-start m-2 lg:m-6">
          <p className="text-4xl font-bold leadi lg:text-6xl">500+</p>
          <p className="text-sm sm:text-base flex items-center justify-center gap-2">
            <img className="h-[25px] w-[25px]" src={instaIcon} alt="" />{" "}
            {/*instagram  icon*/}
            Followers
          </p>
          <a
            target="_blank"
            className="hover:underline"
            href="https://www.instagram.com/chiranjeebnayak.37/"
            rel="noreferrer"
          >
            on Instagram
          </a>
        </div>

        {/* linkedin stats div  */}
        <div className="flex flex-col justify-start m-2 lg:m-6">
          <p className="text-4xl font-bold leadi lg:text-6xl">2K+</p>
          <p className="text-sm sm:text-base flex items-center justify-center gap-2">
            <img className="h-[25px] w-[25px]" src={linkedinIcon} alt="" />{" "}
            {/*linkedin icon*/}
            Followers
          </p>
          <a
            target="_blank"
            className="hover:underline"
            href="https://www.linkedin.com/in/chiranjeeb-nayak/"
            rel="noreferrer"
          >
            on Linkedin
          </a>
        </div>
      </div>
    </section>
  );
}

export default Counter;
