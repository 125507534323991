import React, { useState } from "react";
import Logo from "../assets/Codemap-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuIcon from "../icons/menu.png";
import axios from "axios";
import { IoPersonCircleOutline } from "react-icons/io5";
import MyProfileMenu from "./MyProfileMenu";
import { HiOutlineShoppingCart } from "react-icons/hi2";
import { useSelector } from "react-redux";
import ThemeToggleButton from "./ThemeToggleButton";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const cartCount = useSelector((state) => state.cart.cart.length);
  const [menuVisible, setMenuVisible] = useState(false);
  const userEmail = localStorage.getItem("userEmail");
  const validUser = () => {
    if (userEmail === undefined || userEmail === null || userEmail === "") {
      return false;
    } else {
      return true;
    }
  };
  // eslint-disable-next-line
  const [userValid, setUserValid] = useState(validUser);

  // ham menu open/close function
  const toggleMenu = () => {
    if (!menuVisible) {
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  };

  //logout function
  axios.defaults.withCredentials = true;
  const handleLogout = async () => {
    try {
      const resp = await axios.get(
        `${process.env.REACT_APP_BE_HOST}/api/logout`
      );
      const data = resp.data;
      if (data.apiResponse === 0 || data.data === true) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userCourses");
        navigate("/");
        setUserValid(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {/* nav bar  */}
      <nav className="navBar bg-white dark:bg-gray-800 dark:text-white fixed top-0 left-0 right-0 z-40 shadow-md w-screen">
        <div className="container mx-auto flex items-center justify-between h-20 font-semibold w-full px-6">
          {/* logo div  */}
          <div className="logo h-full w-fit overflow-hidden flex items-center px-3">
            <Link to="/">
              <img
                src={Logo}
                className="dark:invert transition-all delay-500 w-36 mx-2"
                alt=""
              />
            </Link>
          </div>

          {/* //ham menu icon  */}
          <img
            onClick={toggleMenu}
            className={`cursor-pointer w-8 h-8 inline-block mr-4 md:mr-8 lg:hidden ${
              menuVisible && "rotate-90"
            }`}
            src={menuIcon}
            alt=""
          />

          {/* nav links  */}
          <ul
            onClick={() => setMenuVisible(false)}
            className={` ${
              menuVisible ? "flex" : "hidden"
            } fixed z-50 top-16 left-0 h-[calc(100vh-4rem)] bg-white lg:bg-transparent w-screen flex-col gap-6 items-center md:justify-start lg:justify-center pt-20 lg:pt-0
        lg:static lg:flex lg:flex-row lg:gap-2 lg:h-20 lg:w-fit lg:items-center lg:mr-16 transition-all delay-500`}
          >
            {/* home link navbar  */}
            <li>
              <Link
                to="/"
                className={`px-4 py-2 rounded-md hover:bg-gray-100 dark:hover:bg-blue-600 ${
                  location.pathname === "/" || location.pathname === "/home"
                    ? "bg-gray-200 dark:bg-blue-600"
                    : ""
                }`}
              >
                Home
              </Link>
            </li>

            {userValid ? (
              <>
                {/* my learning link navbar  */}
                <li>
                  <Link
                    to="/mylearnings"
                    className={`px-4 py-2 rounded-md hover:bg-gray-100 ${
                      location.pathname === "/mylearnings" && "bg-gray-200"
                    }`}
                  >
                    My Learning
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}

            {/* courses dropdown menu  */}
            <li className="flex flex-col items-center justify-center relative group">
              <Link
                to="/courses"
                className={`flex justify-between md:inline-flex px-4 py-2 items-center rounded-md hover:bg-gray-100 dark:hover:bg-blue-600 space-x-2 ${
                  location.pathname === "/courses" && "bg-gray-200 dark:bg-blue-600"
                }`}
              >
                <span>Courses</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 fill-current pt-1 hidden lg:inline-block"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </Link>

              {/* courses links  */}
              <ul className="dark:bg-[--dark-bg-color] lg:group-hover:opacity-100 group-hover:h-auto group-hover:overflow-visible group-hover:translate-y-0 opacity-0 h-0 overflow-hidden transform translate-y-[-10%] transition-all duration-300 rounded-md shadow-xl md:absolute top-full left-0 min-w-64 bg-white md:shadow-lg md:rounded-b">
                <li className="relative overflow-hidden">
                  <Link
                    to="/course/details?courseName=placmentBootcamp"
                    className="flex px-4 py-4 hover:bg-gray-100 text-sm dark:hover:bg-gray-600 "
                  >
                    Placment Bootcamp(DSA + MERN)
                  </Link>
                  <span className="absolute top-3 px-8 -right-9 bg-blue-500 rotate-45 text-xs text-white">
                    Popular
                  </span>
                </li>
                <li className="relative overflow-hidden">
                  <Link
                    to="/course/details?courseName=dsa"
                    className="flex px-4 py-4 hover:bg-gray-100 text-sm dark:hover:bg-gray-600"
                  >
                    DSA (Data structures and Algorithm)
                  </Link>
                </li>
                <li className="relative overflow-hidden">
                  <Link
                    to="/course/details?courseName=mern"
                    className="flex px-4 py-4 hover:bg-gray-100 text-sm dark:hover:bg-gray-600"
                  >
                    FullStack Development(MERN)
                  </Link>
                </li>
              </ul>
            </li>

            {!userValid ? (
              <>
                {/* contact page link */}
                <li>
                  <Link
                    to="/contact"
                    className={`px-4 py-2 rounded-md hover:bg-gray-100 dark:hover:bg-blue-600 ${
                      location.pathname === "/contact" && "bg-gray-200 dark:bg-blue-600"
                    }`}
                  >
                    Contact
                  </Link>
                </li>

                {/* login button  */}
                <Link
                  to="/login"
                  className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-blue-600 rounded-md"
                >
                  Login
                </Link>

                {/* signup button  */}
                <Link
                  to="/signup"
                  className="px-6 py-2 transition ease-in delay-150 border-blue-600 border-2 rounded-md hover:bg-[--button-color] hover:text-white"
                >
                  Signup
                </Link>
              </>
            ) : (
              <>
                {/* my profile  */}
                <li>
                  <Link
                    to="/profile"
                    className={`inline-block lg:hidden px-4 py-2 rounded-md hover:bg-gray-100 ${
                      location.pathname === "/profile" && "bg-gray-200"
                    }`}
                  >
                    My Profile
                  </Link>
                </li>

                {/* explore more courses  */}
                <li>
                  <Link
                    to="/courses"
                    className={`inline-block lg:hidden px-4 py-2 rounded-md hover:bg-gray-100 ${
                      location.pathname === "/courses" && "bg-gray-200"
                    }`}
                  >
                    Explore Courses
                  </Link>
                </li>

                {/* Support  */}
                <li>
                  <Link
                    to="/support"
                    className={`inline-block lg:hidden px-4 py-2 rounded-md hover:bg-gray-100 ${
                      location.pathname === "/support" && "bg-gray-200"
                    }`}
                  >
                    Support
                  </Link>
                </li>

                {/* logout button  */}
                <li className="cursor-pointer" onClick={handleLogout}>
                  <span className="inline-block lg:hidden px-4 py-2 rounded-md hover:bg-gray-100">
                    Logout
                  </span>
                </li>

                {/* hover menu over 768px screens only */}
                <div className="hidden lg:inline-block relative group">
                  <IoPersonCircleOutline className="size-10 cursor-pointer " />
                  <MyProfileMenu
                    handleLogout={handleLogout}
                    location={location}
                  />
                </div>
              </>
            )}
            {/* cart link  */}
            <li
              title="Cart"
              className={`relative lg:mx-4 p-2 ${
                location.pathname === "/cart" && "bg-gray-200"
              } rounded hover:bg-gray-100 dark:hover:bg-blue-600 dark:bg-blue-600 group`}
            >
              <Link to={"/cart"}>
                <HiOutlineShoppingCart size={20} />
                <span
                  className={`absolute group-hover:-top-2 group-hover:-right-2 bg-blue-500 px-1 py-0 rounded-full text-sm text-white transition-all delay-75 ${
                    location.pathname === "/cart"
                      ? "-top-2 -right-2"
                      : "-top-0 -right-0"
                  }`}
                >
                  {cartCount}
                </span>
              </Link>
            </li>
            <li>
            <ThemeToggleButton />
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;
