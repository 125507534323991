import React, {useState} from "react";

function CheckoutForm({setUserInfo}) {
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('')
    const [name, setName] = useState('');
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setUserInfo({ name, email, contact });
  };

  return(
    <div className="p-8">
      <h1 className="text-2xl font-semibold mb-4">User Checkout Form</h1>
        <form onSubmit={handleSubmit} className="space-y-4 w-96 mx-auto">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-[--tagline-color]">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter your name"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-[--tagline-color]">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter your email"
            />
          </div>

          <div>
            <label htmlFor="contact" className="block text-sm font-medium text-gray-700 dark:text-[--tagline-color]">Contact Number</label>
            <input
              type="text"
              id="contact"
              name="contact"
              required
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder="Enter your contact number"
            />
          </div>

          <div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Submit
            </button>
          </div>
        </form>
    </div>
  );
}

export default CheckoutForm;
